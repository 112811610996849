<template>
  <div class="TraceabilityList">
    <div class="tab_box">
      <div class="item" v-for="(item, index) in tabList" :key="index">
        <el-tooltip :content="item.content" placement="bottom" effect="light">
          <div
            class="start"
            :class="tabIndex == index ? 'cf' : ''"
            @click="handleClick(index)"
          >
            <img :src="item.image" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </el-tooltip>
      </div>

      <div class="progress">
        溯源进度:
        <el-progress
          :stroke-width="10"
          :show-text="false"
          :percentage="progress"
        ></el-progress>
      </div>
    </div>

    <div class="TraceabilityList_box" v-if="tabIndex >= 1">
      <div
        class="office-tracesource-table"
        ref="tablewrap"
        v-if="traceReportList.length != 0"
      >
        <div class="search_nav">
          <div class="sort">
            <div
              class="sort_item"
              v-for="(item, index) in sortArr"
              @click="sortClick(item, index)"
              :key="item.id"
            >
              <span :class="{ 'is-active': currentSortIndex == index }">{{
                item.title
              }}</span>
              <i
                class="right-item-icon"
                :class="item.sort == true ? 'up' : 'down'"
              ></i>
            </div>

            <div class="refresh" @click="gettraceList">
              <i class="el-icon-refresh"></i>
            </div>
          </div>

          <div class="search-left">
            <el-input
              placeholder="请输入文件名搜索"
              v-model="searchKey"
              @keyup.enter.native="gettraceList"
            ></el-input>
            <div class="search-btn" @click="gettraceList">
              <!-- <img :src="require('assets/images/included_search.png')" alt="" /> -->
              <i class="el-icon-search"></i>
            </div>
          </div>
        </div>
        <div class="traceReportList">
          <div
            class="trace_item"
            v-for="item in traceReportList"
            :key="item.id"
          >
            <img
              :src="require(`@/assets/images/disk/Icons/${item.num}.png`)"
              alt=""
            />
            <div class="info">
              <div class="title">{{ item.fileName }}</div>
              <div class="other">
                <div class="evaluate-wrap">
                  <div
                    class="nothing"
                    v-if="item.status === 0 && !item.evaluation"
                  >
                    溯源评价:未溯源
                  </div>
                  <div class="failed" v-if="item.status === 3">
                    溯源评价:{{ item.status_remark }}
                  </div>
                  <div
                    v-if="
                      (item.reportType == 1 || item.reportType == 0) &&
                      item.evaluation
                    "
                    class="original"
                  >
                    <span>溯源评价:</span>
                    <img
                      v-if="item.level == 'A+'"
                      class="levelIcon"
                      :src="
                        item.level == 'A+'
                          ? require('assets/images/A+.png')
                          : ''
                      "
                      alt=""
                    />
                    <img
                      v-if="item.level == 'A'"
                      class="levelIcon"
                      :src="
                        item.level == 'A' ? require('assets/images/A.png') : ''
                      "
                      alt=""
                    />
                    <img
                      v-if="item.level == 'B+'"
                      class="levelIcon"
                      :src="
                        item.level == 'B+'
                          ? require('assets/images/B+.png')
                          : ''
                      "
                      alt=""
                    />
                    <img
                      v-if="item.level == 'B'"
                      class="levelIcon"
                      :src="
                        item.level == 'B' ? require('assets/images/B.png') : ''
                      "
                      alt=""
                    />
                    <img
                      v-if="item.level == 'C'"
                      class="levelIcon"
                      :src="
                        item.level == 'C' ? require('assets/images/c.png') : ''
                      "
                      alt=""
                    />
                    <span class="txt">原创率{{ item.evaluation }}%</span>
                  </div>
                  <div
                    v-if="item.reportType > 1 && item.evaluation"
                    class="homology"
                  >
                    溯源评价:
                    <img
                      v-if="item.evaluation == '有同源'"
                      :src="require('assets/images/homology-y.png')"
                      alt=""
                    />
                    <img
                      v-else
                      :src="require('assets/images/homology-n.png')"
                      alt=""
                    />
                    <span
                      :class="[
                        'txt',
                        item.evaluation == '有同源'
                          ? 'is-homology'
                          : item.evaluation == '无同源'
                          ? 'no-homology'
                          : '',
                      ]"
                    >
                      {{ item.evaluation }}
                    </span>
                  </div>
                </div>
                <div class="time">溯源时间:{{ item.createTime }}</div>
              </div>
            </div>
            <div class="more">
              <el-popover
                popper-class="option-popper"
                placement="bottom"
                trigger="click"
              >
                <div class="option_item" @click.stop="viewDetail(item)">
                  查看详情
                </div>

                <div
                  class="option_item"
                  :data-subitem="item.reportWordUrl || item.reportUrl"
                  title="溯源报告"
                  v-if="completeStatus(item.status)"
                  @click="sourceReportOption(item)"
                >
                  溯源报告
                </div>
                <div
                  class="option_item"
                  :data-subitem="item.url"
                  title="源文件"
                  v-if="completeStatus(item.status)"
                  @click="sourceFile(item)"
                >
                  源文件
                </div>
                <div class="option_item" @click="delHandler(item.id)">删除</div>
                <div
                  v-if="item.originalIds"
                  title="在线授权"
                  :class="[
                    'authorize',
                    'option_item',
                    item.originalIds && item.originalIds != ''
                      ? 'is-blue'
                      : 'is-gray',
                  ]"
                  @click="showAuthorize(item.originalIds, item.id, item)"
                >
                  {{
                    item.originalIds && item.originalIds != ""
                      ? "可在线授权"
                      : "无在线授权"
                  }}
                </div>
                <div
                  class="option_item"
                  title="分享"
                  v-if="completeStatus(item.status)"
                  @click.stop="
                    QrCodeShow(
                      item.reportWordUrl ? item.reportWordUrl : item.url,
                      item.fileName
                    )
                  "
                >
                  分享
                </div>

                <img
                  slot="reference"
                  :src="require('assets/images/headers/more.png')"
                  alt=""
                />
              </el-popover>
            </div>
            <div
              :class="[
                'states',
                item.status === 0
                  ? 'orange'
                  : item.status === 1
                  ? 'blue'
                  : item.status === 2
                  ? 'green'
                  : 'gray',
              ]"
            >
              {{
                item.status === 0
                  ? "未溯源"
                  : item.status === 1
                  ? "溯源中"
                  : item.status === 2
                  ? "完成"
                  : "失败"
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="office-tracesource-footer" v-if="traceReportList.length != 0">
        <el-pagination
          layout="total, prev, pager, next, jumper"
          :pager-count="5"
          :current-page="page"
          :page-size="pageSize"
          @current-change="currentChange"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <div class="depthLearn_box" v-else>
      <div class="recommend_box">
        <div class="top">
          <div class="txt" @click="handleClickDep">
            <img
              style="vertical-align: middle"
              :src="require('assets/images/base_map2.png')"
              alt=""
            />
            <span>溯源·第一议题学习专栏</span>
          </div>
          <div class="txt1" @click="handleClickDep">查看更多>></div>
        </div>

        <div class="bottom" v-if="article.length != 0">
          <div
            v-for="(v, index) in article"
            :key="index"
            class="article_item"
            @click="openArticle(v)"
          >
            <span>{{ index + 1 }}</span> {{ v.fileName }}
          </div>
        </div>

        <div class="kong" v-else>
          <img :src="require('assets/images/kong.png')" alt="" />
          <div>暂无数据</div>
        </div>
      </div>
    </div>

    <!-- 详细信息 -->
    <el-dialog title="详细信息" :visible.sync="infoFilesPopup" class="addFil">
      <div class="box">
        <span class="boxSpan">文件名：</span>&nbsp;&nbsp;&nbsp;
        <span> {{ valInfo.fileName }}</span>
        <!-- <img
          @click="downloadFil"
          class="download"
          :src="require('assets/images/info/download.png')"
          alt=""
        /> -->
      </div>
      <div class="box">
        <span class="boxSpan">类型：</span>&nbsp;&nbsp;&nbsp;
        <span>
          {{
            valInfo.reportType == 1
              ? "文档"
              : valInfo.reportType == 2
              ? "图片"
              : valInfo.reportType == 3
              ? "视频"
              : valInfo.reportType == 4
              ? "音频"
              : valInfo.reportType == 0
              ? "富文本"
              : "-"
          }}
        </span>
      </div>
      <div class="box">
        <span class="boxSpan">数据大小：</span>&nbsp;&nbsp;&nbsp;
        <span>{{ valInfo.fs }}</span>
      </div>
      <div class="box">
        <span class="boxSpan">字符：</span>&nbsp;&nbsp;&nbsp;
        <span>{{ valInfo.price }}</span>
      </div>
    </el-dialog>

    <!-- 链接二维码弹窗 -->
    <el-dialog
      title="二维码"
      :close-on-click-modal="false"
      :visible.sync="QrCodeVisible"
      @close="handleCloseQrcode"
      class="qrcode"
    >
      <div class="content">
        <div class="notes">分享二维码</div>
        <div class="imgBox">
          <div ref="qrcode_box"></div>
        </div>
        <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { previewUrl } from "api/common";
import { findTraceReport, delHisTraceSource } from "api/traceTheSource";
import { depthLearnList } from "api/idiom";
import { getToken } from "utils/auth";
import QrCode from "qrcodejs2";
export default {
  name: "TraceabilityList",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    progress: {},
  },
  data() {
    //这里存放数据
    return {
      tabIndex: 0,
      tabList: [
        {
          name: "学习专栏",
          image: require("assets/images/base_map2.png"),
          content: "溯源·第一议题学习专栏",
        },
        {
          name: "溯源报告",
          image: require("assets/images/tracesource-report.png"),
          content: "溯源后标注原创来源的报告",
        },
        {
          name: "批量溯源",
          image: require("assets/images/tracesource-report.png"),
          content: "批量上传doc docx文档进行溯源",
        },
      ],
      // 分页
      page: 1,
      // 总数
      total: 0,
      article: [],
      pageSize: 8,
      //历史溯源报告表格数据
      traceReportList: [],
      sortArr: [
        //排序数组
        {
          title: "溯源时间",
          sort: false, //true 升序 false降序
          id: 1,
        },
        {
          title: "原创率",
          sort: true,
          id: 2,
        },
      ],

      sortOrder: "2",
      sort: "1",
      searchKey: "", //关键字查询v-model
      currentSortIndex: 0,
      //详情弹窗
      infoFilesPopup: false,
      //详情数据
      valInfo: {},
      // 分享二维码弹窗
      QrCodeVisible: false,
      qr: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    sortClick(item, index) {
      // console.log(item);
      item.sort = !item.sort;
      this.currentSortIndex = index;
      if (item.sort) {
        this.sortOrder = "1";
      } else {
        this.sortOrder = "2";
      }
      if (item.title == "溯源时间") {
        this.sort = "1";
      } else if (item.title == "原创率") {
        this.sort = "2";
      }

      this.gettraceList();
    },
    currentChange(val) {
      this.page = val;
      this.gettraceList();
    },

    //溯源完成状态
    completeStatus(s) {
      return s == 2;
    },

    // 详情弹窗
    viewDetail(val) {
      this.infoFilesPopup = true;
      this.valInfo = val;
    },
    handleClick(index) {
      if (index == 1) {
        if (!getToken("auth_token")) {
          this.$message({
            message: "请先登录！",
            duration: 1500,
          });

          return window.loginFun();
        }
      }
      this.tabIndex = index;
      if (index == 2) {
        if (!getToken("auth_token")) {
          this.$message({
            message: "请先登录！",
            duration: 1500,
          });

          return window.loginFun();
        }
        this.$emit("handleIsUp");
      }
    },
    // 获取表格数据
    async gettraceList(after) {
      const data = {
        page: this.page,
        limit: this.pageSize,
        sort: this.sort,
        sortType: this.sortOrder,
        key: this.searchKey,
      };
      if (!this.noNeedLoading) {
        this.$store.commit("playLifeLoading", true);
      }
      const res = await findTraceReport(data);
      if (typeof after === "function") after();
      this.$store.commit("playLifeLoading", false);
      if (res.code === 200) {
        this.traceReportList = res.data.data;

        this.traceReportList.forEach((item) => {
          switch (item.reportType) {
            case 0:
              item.num = "07";
              break;
            case 1:
              item.num = "07";
              break;
            case 2:
              item.num = "09";
              break;
            case 3:
              item.num = "11";
              break;
            case 4:
              item.num = "12";
              break;
            case 5:
              item.num = "11";
              break;
          }
        });
        // console.log(this.traceReportList);
        this.total = res.data.total;
        // this.pageCount = Math.ceil(this.traceReportTotal / this.pageSize);
      } else {
        this.traceReportList = [];
        this.total = 0;
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 溯源报告
    sourceReportOption(item) {
      window.open(
        this.$store.state.onlineBasePath +
          (item.reportWordUrl
            ? item.reportWordUrl + "?attname=溯源报告-" + item.fileName
            : item.reportUrl + "?attname=溯源报告-" + item.fileName),
        "_blank"
      );
    },
    // 源文件
    sourceFile(item) {
      console.log(item);
      window.open(
        this.$store.state.onlineBasePath +
          item.url +
          "?attname=" +
          item.fileName,
        "_blank"
      );
    },

    //点击删除事件
    delHandler(reportId) {
      this.$confirm("是否删除该文件", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await this.delReport(reportId);
        this.gettraceList();
      });
    },

    //删除报表
    async delReport(reportId) {
      const params = { reportId };
      this.$store.commit("playLifeLoading", true);
      const res = await delHisTraceSource(params);
      this.$store.commit("playLifeLoading", false);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "删除成功",
        });
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 打开分享二维码弹窗
    QrCodeShow(pdf, fileName) {
      this.QrCodeVisible = true;

      this.$nextTick(() => {
        let fileName1 = fileName;
        // let fileName1 = fileName + '溯源报告.pdf'
        this.crateQrcode(pdf, fileName1);
      });
    },

    // 生成二维码
    crateQrcode(pdf, fileName) {
      const params = {
        fileName: encodeURIComponent(fileName),
        url: encodeURIComponent(pdf),
      };
      let code = this.$store.state.user_info
        ? this.$store.state.user_info.recommendcodeVo.code
        : "";
      this.qr = new QrCode(this.$refs.qrcode_box, {
        text: `https://mall.writemall.com/share?fileName=${params.fileName}&url=${params.url}&code=${code}`,
        width: 128,
        height: 128,
        correctLevel: 3,
        colorDark: "#000000",
        colorLight: "#ffffff",
      });
    },

    async getDepthLearnList() {
      const form = new FormData();
      form.append("page", 1);
      form.append("limit", 15);

      const res = await depthLearnList(form);
      if (res.code == 200) {
        this.article = res.data.data;
      }
    },

    openArticle(val) {
      previewUrl({
        url: this.$store.state.onlineBasePath + val.traceabilityWordPath,
      }).then((res) => {
        window.open(res.data);
      });
    },

    //获取完整编码的预览地址
    async previewPdf(source) {
      const params = {
        url: this.basePath + source,
      };
      const res = await previewUrl(params);
      if (res.code == 200) {
        this.fullPreviewPdf = res.data;
      }
    },

    // 关闭去除二维码
    handleCloseQrcode() {
      this.$refs.qrcode_box.innerHTML = "";
    },

    handleClickDep() {
      this.$router.push("/depthLearn");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.gettraceList();
    this.getDepthLearnList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.TraceabilityList {
  .tab_box {
    font-size: 13px;
    padding: 10px 20px;
    background-color: #ff690020;
    margin-bottom: 10px;
    @include flex-start;

    .progress {
      flex: 1;
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 13px;
      padding-right: 20px;
      .el-progress {
        flex: 1;
        margin-left: 10px;
      }
      ::v-deep .el-progress-bar__innerText {
        // color: red;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .item {
      margin-right: 20px;
    }
    .start {
      display: flex;
      align-items: center;
    }

    .cf {
      color: #ff6900;
    }

    > div {
      @include flex-start;

      cursor: pointer;

      > span {
        font-size: 13px;
        font-weight: 500;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }

  .office-tracesource-table {
    padding: 10px 20px;
    .search_nav {
      @include flex-between;
      padding: 0 10px;
      .sort {
        display: flex;
        .sort_item {
          margin-right: 20px;
          font-size: 12px;
          color: #666;
          cursor: pointer;
          .is-active {
            color: #ff6900;
          }
          i {
            display: inline-block;
            width: 9px;
            height: 13px;
            margin-left: 6px;
          }
          .up {
            @include backgroundGroup(
              "~assets/images/goldenSentence/sortIcon-blue-up.png"
            );
          }

          .down {
            @include backgroundGroup(
              "~assets/images/goldenSentence/sortIcon-blue.png"
            );
          }
        }

        .refresh {
          cursor: pointer;
        }
      }

      .search-left {
        @include flex-start;
        width: 291px;
        height: 41px;
        background: #ffffff;
        border: 1px solid #d9dee4;
        border-radius: 20px;
        ::v-deep .el-input {
          // margin-left: 140px;
          flex: 1;
          // margin-right: 20px;
          // width: 400px;
          .el-input__inner {
            border: none;
            padding: 0px;
            // margin: 0 10px;
            margin-left: 10px;
            width: 90%;
          }
          .el-input__inner::-webkit-input-placeholder {
            /* 使用webkit内核的浏览器 */
            // color: #e0484b;
            line-height: 40px;
          }
        }
        .search-btn {
          // background-color: #3683f2;
          height: 100%;
          // border-radius: 5px;
          width: 50px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            color: #4587ff;
            font-size: 18px;
          }
          // img {
          //   cursor: pointer;
          //   position: absolute;
          //   top: 50%;
          //   left: 50%;
          //   transform: translate(-50%, -50%);
          // }
        }
      }
    }
    .traceReportList {
      height: calc(100% - 42px);
      overflow: auto;
      font-size: 14px;
      margin-top: 10px;
      .trace_item {
        margin: 6px auto;
        padding: 10px 5px;
        width: 97%;
        cursor: pointer;
        // border-bottom: 1px solid #ccc;
        // box-shadow: 0px 0px 10px 2px #eee;
        border-bottom: 0.0625rem solid #efefef;
        display: flex;
        align-items: center;
        height: 60px;
        position: relative;
        > img {
          width: 60px;
          height: 60px;
        }
        .more {
          position: absolute;
          right: 5%;
          bottom: 10%;
          cursor: pointer;
        }
        .states {
          position: absolute;
          right: 50px;
          top: 30%;
          width: 50px;
          height: 20px;
          margin-top: -9px;
          font-size: 12px;
          font-weight: 800;
          text-align: center;
          line-height: 20px;
          color: #fff;
          z-index: 1;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: -1;
          }
        }

        .gray {
          &::before {
            background: url("~assets/images/rhombus-gray.png") no-repeat;
            background-size: 100% 100%;
          }

          color: #999;
        }

        .green {
          &::before {
            background: url("~assets/images/rhombus-green.png") no-repeat;
            background-size: 100% 100%;
          }
        }

        .orange {
          &::before {
            background: url("~assets/images/rhombus-orange.png") no-repeat;
            background-size: 100% 100%;
          }
        }

        .blue {
          &::before {
            background: url("~assets/images/rhombus-blue.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .info {
          // display: flex;
          // flex-direction: column;
          // justify-items: center;
          height: 100%;
          width: 70%;
          .title {
            // font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 30px;
            height: 30px;
            padding-left: 15px;
            flex: 1;
          }
          .other {
            color: gray;
            flex: 1;
            display: flex;
            width: 100%;
            align-items: center;
            // justify-content: space-around;
            line-height: 30px;
            height: 30px;
            padding-left: 15px;
            .evaluate-wrap {
              width: 50%;
              .original {
                display: flex;
                align-items: center;
              }
              .homology {
                display: flex;
                align-items: center;
              }
              img {
                width: 18px;
                height: 18px;
                margin: 0 10px;
              }
            }
            .time {
              width: 50%;
            }
          }
        }
        &:hover {
          background: #ffffff;
          box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
          border-radius: 5px;
        }
      }
    }
  }

  .office-tracesource-footer {
    padding: 10px 20px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }

  .recommend_box {
    padding: 10px 20px;

    .top {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .txt {
        cursor: pointer;
        font-size: 14px;
        //font-weight: bold;
        display: flex;
        align-items: center;
        img {
          // width: 200px;
          height: 25px;
        }
        span {
          margin-left: 12px;
          font-family: "黑体";
          font-size: 14px;
          // color: #4587ff;
        }
      }
      .txt1 {
        cursor: pointer;
        font-size: 14px;
        color: #999999;
      }
    }
    .kong {
      text-align: center;
      font-size: 14px;
      color: #999;
      margin: 30vh 0;
      img {
        width: 150px;
        height: 150px;
      }
    }
    .bottom {
      .article_item {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
        margin: 20px 0;
        font-size: 14px;

        &:nth-child(1) {
          span {
            color: #f26d5f;
          }
        }
        &:nth-child(2) {
          span {
            color: #ff8200;
          }
        }
        &:nth-child(3) {
          span {
            color: #4587ff;
          }
        }

        span {
          font-weight: bold;
          margin-right: 10px;
          color: #999999;
        }
      }
    }
  }

  // 详情弹窗
  .addFil {
    margin: 0 auto;

    .box {
      margin-top: 20px;

      &:nth-child(1) {
        margin-top: 0;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;

        display: flex;

        span {
          &:nth-child(2) {
            flex: 1;
          }
        }

        .download {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .qrcode {
    ::v-deep .el-dialog {
      // top: 50%;
      // transform: translateY(-50%);
      margin-top: 25vh !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);

      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;

        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }

        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }

      .el-dialog__body {
        padding: 65px 20px;

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }

          .imgBox {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
