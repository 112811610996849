<template>
  <div class="wangeditor">
    <!-- <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    /> -->

    <div class="box">
      <div class="jia_box">
        <div class="box_jia"></div>
        <div class="box_jia"></div>
      </div>

      <Editor
        class="Editor_box"
        style="overflow-y: hidden"
        :defaultConfig="editorConfig"
        v-model="html"
        @onCreated="onCreated"
        @onChange="onChange"
      />
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  name: "wangeditor",
  //import引入的组件需要注入到对象中才能使用
  components: {
    Editor,
    // Toolbar,
  },
  props: {
    ParameHtml: {},
  },
  data() {
    //这里存放数据
    return {
      editor: null,
      html: "",
      editorConfig: {
        placeholder: "请输入内容...",
        hoverbarKeys: {
          text: {
            menuKeys: [
              "undo",
              "redo",
              "headerSelect",
              "fontFamily",
              "bulletedList",
              "|",
              "bold",
              "color",
              "clearStyle",
              "|",
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
        },
      },
      mode: "default", // or 'simple'
      toolbarConfig: {
        toolbarKeys: [
          /* 显示哪些菜单，如何排序、分组 */
          "undo",
          "redo",
          "headerSelect",
          "bold",
          "underline",
          "italic",
          {
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path></svg>',
            key: "group-more-style",
            menuKeys: ["through", "sup", "sub", "clearStyle"],
            title: "更多",
          },
          "color",
          "fontSize",
          "fontFamily",
          {
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            key: "group-justify",
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
            title: "对齐",
          },
        ],
        excludeKeys: [
          /* 排除菜单组，写菜单组 key 的值即可 */
        ],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    ParameHtml(val) {
      this.html = val;
    },
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },

    onChange(val) {
      this.$emit("handleHtml", this.html);
    },
  },
  mounted() {},
  created() {},
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.wangeditor {
  // 编辑器
  .box {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    width: 95%;
    margin: 20px auto;

    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.1);
    .jia_box {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 10px;
      .box_jia {
        width: 35px;
        height: 35px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        &:nth-child(2) {
          width: 35px;
          height: 35px;
          border-right: 0px solid #000;
          border-bottom: 0px solid #000;
          border-left: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        }
      }
    }

    .Editor_box {
      padding: 0 45px;
      height: 745px;

      ::v-deep .w-e-text-container {
        ::-webkit-scrollbar {
          // height: 100%;
          display: none;
        }
      }
    }
  }
}
</style>
