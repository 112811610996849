<template>
  <div class="TraceSourceRouterView">
    <div class="box">
      <div class="left leftFlex">
        <div class="Trace_box">
          <div class="tab_box">
            <div class="item" v-for="(item, index) in tabList" :key="index">
              <el-tooltip
                :content="item.content"
                placement="bottom"
                effect="light"
              >
                <div class="start" @click="handleTab(index)">
                  <img :src="item.image" alt="" />
                  <span>{{ item.name }}</span>
                </div>
              </el-tooltip>
            </div>

            <div class="sum_box">{{ sum || 0 }} 字</div>
          </div>

          <div class="Editor_box">
            <wangeditor :ParameHtml="html" @handleHtml="handleHtml" />
          </div>
        </div>
        <!-- <router-view></router-view> -->
      </div>
      <div class="right recommendRightList">
        <TraceabilityList
          @handleIsUp="upListDialog = true"
          :progress="progress"
          ref="refTraceList"
        />
      </div>
    </div>

    <div class="commonFooter_box">
      <recommend :isflex="true" />
      <commonFooter />
    </div>

    <!-- 上传文件 -->
    <input
      id="photos"
      type="file"
      @change="realUpload"
      class="shuruw"
      name="frontFile"
      accept=".doc,.docx"
      ref="backFile"
      style="opacity: 0"
    />

    <!--数据源时间选择-->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="溯源截止日期（根据选择日期内数据进行溯源）"
      :visible.sync="sourceTimespan"
      class="source-timespan"
    >
      <div class="content">
        <div class="time-wrap">
          <div class="date-wrap">
            <el-date-picker
              v-model="sourceEndTime"
              type="date"
              placeholder="结束时间"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="footer">
        <span class="start" @click="startTraceSource">开始溯源</span>
        <span class="cancel" @click="sourceTimespan = false">取消溯源</span>
      </div>
    </el-dialog>

    <!-- 提示弹窗 -->
    <el-dialog title="提示" :visible.sync="dialogTips" class="tips">
      <div class="content" v-if="curCharacter >= sum">
        <div class="content-text">
          本次溯源将扣减<span>{{ sum }}</span
          >个字符，字符包现有<span>{{ curCharacter }}</span
          >个字符，该字符将支付给重复字段的原创作者。
        </div>
      </div>
      <div v-else class="content" :class="curCharacter < sum ? 'active' : ''">
        <i class="content-icon"></i>
        <div class="content-text">
          本次溯源将扣减
          <span>{{ sum }}</span
          >个字符，字符包现有 <span>{{ curCharacter }}</span
          >个字符，<span>已不足。</span>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btn" @click="sureSubmit">
          {{ curCharacter >= sum ? "确定" : "取消" }}
        </div>
        <div
          v-if="curCharacter >= sum"
          class="footer-btn"
          @click="handleQuickPay"
        >
          字符包快捷支付
        </div>
        <div v-else class="footer-btn" @click="$router.push('/details/2')">
          购买字符包
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="上传文件"
      width="500px"
      :visible.sync="upListDialog"
      :before-close="
        () => {
          upListDialog = false;
          fileList = [];
        }
      "
    >
      <div class="upListDialog">
        <el-upload
          class="upload-demo"
          ref="upload"
          action
          :on-change="handleChange"
          :on-remove="handleRemove"
          :file-list="fileList"
          accept=".doc,.docx"
          :multiple="true"
          :auto-upload="false"
        >
          <el-button slot="trigger" size="small" type="primary"
            >选取文件</el-button
          >
        </el-upload>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            () => {
              upListDialog = false;
              fileList = [];
            }
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="handleUp">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import wangeditor from "components/editor/wangeditor.vue";
import recommend from "components/recommend/recommend.vue";
import commonFooter from "components/footer/commonFooter.vue";
import { getToken } from "utils/auth";
import TraceabilityList from "./TraceabilityList.vue";
import {
  wrodFileToHtml,
  traceSource,
  getTraceSourceResult,
  checkInterestsNumber,
  getTraceSetup,
  postTraceSetup,
  uploadFileTraceSource,
  getTraceSourceResultList,
  preserveWord,
} from "api/traceTheSource";

export default {
  data() {
    return {
      tabList: [
        {
          name: "开始溯源",
          image: require("assets/images/tracesource-report.png"),
          content:
            "对文档的篇,段,句追溯原创出处,统计原创率,需要根据文档字数扣减字符包字符",
        },
        {
          name: "导入Word",
          image: require("assets/images/import_word.png"),
          content: "导入Word到编辑器内展示",
        },
        {
          name: "保存Word",
          image: require("assets/images/download_blue.png"),
          content: "下载保存文件",
        },
        {
          name: "字符余额 " + (this.$store.state.user_info?.character || 0),
          image: require("assets/images/tracesource-report.png"),
          content: "字符余额",
        },
      ],
      //溯源进度
      progress: 0,
      // 文本
      html: "",
      // 字符数
      sum: 0,
      //数据源时间范围选择
      sourceTimespan: false,
      //数据源结束时间
      sourceEndTime: this.format(new Date()),
      userId: this.$store.state.user_info && this.$store.state.user_info.id, //用户id
      //显示字符数量
      curCharacter: 0,
      //字符支付方式 手动、快捷
      payType: "",
      //显示溯源字符提示
      dialogTips: false,
      Timer: null,
      timer: null,
      // 查询次数
      Count: 0,
      upListDialog: false,
      // 上传列表
      fileList: [],
    };
  },
  components: {
    commonFooter,
    TraceabilityList,
    wangeditor,
    recommend,
  },
  methods: {
    async handleSave() {
      if (this.sum == 0) {
        return this.$message({
          message: "内容不能为空!",
          duration: 1000,
        });
      }
      this.$store.commit("playLifeLoading", true);
      let form = new FormData();
      form.append("fileName", "Word.docx");
      form.append("content", this.html);
      const res = await preserveWord(form);
      if (res) {
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        let objectUrl = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = objectUrl;
        link.setAttribute("download", "Word.docx");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);

        document.body.removeChild(link);
      }
      this.$store.commit("playLifeLoading", false);
    },
    // 查询文件溯源进度
    async checkProgress(ids) {
      const res = await getTraceSourceResultList({ ids });

      ++this.Count;

      if (this.Count == 20) {
        this.Count = 0;
        clearInterval(this.Timer);
        this.$alert("溯源时间较长,请稍后自行刷新溯源报告列表", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            // this.$message({
            //   type: "info",
            //   message: `action: ${action}`,
            // });
          },
        });
        return;
      }

      if (res.code == 200) {
        this.progress = res.data;
        clearInterval(this.timer);

        this.handlerRefTraceList();
        this.$message.success("溯源成功");
      }
    },

    handlerRefTraceList() {
      this.$refs["refTraceList"].page = 1;
      this.$refs["refTraceList"].sortOrder = "2";
      this.$refs["refTraceList"].sort = "1";
      this.$refs["refTraceList"].tabIndex = 1;
      this.$refs["refTraceList"].gettraceList();
    },

    // 文件溯源
    async upFileTrace() {
      this.$message({
        type: "success",
        message: "溯源中",
      });
      this.sourceTimespan = false;
      this.upListDialog = false;
      this.dialogTips = false;
      let form = new FormData();

      this.fileList.forEach((file) => {
        form.append("files", file.raw);
      });

      form.append("endDate", this.sourceEndTime);

      const res = await uploadFileTraceSource(form);

      if (res.code == 200) {
        // this.sum = res.data.price;
        this.fileList = [];
        this.timer = setInterval(() => {
          this.checkProgress(res.data.reportIdList);
        }, 5000);

        this.handlerRefTraceList();
      } else {
        this.$message.error(res.message);
      }
    },

    handleUp() {
      if (this.fileList.length == 0) {
        this.$message.error("请上传文件");
        return;
      }

      this.sourceTimespan = true;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },

    // 删除上传文件
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },

    // 支付字符包
    sureSubmit() {
      if (this.curCharacter > this.sum) {
        if (this.upListDialog) {
          this.upFileTrace();
          return;
        }
        this.sureTraceSource();
      }

      this.dialogTips = false;
    },
    // 开启快捷支付
    handleQuickPay() {
      this.payType = 1;
      this.dialogTips = false;
      this.sureTraceSource();
      this.handleSetupSure();
    },
    // 设置快捷支付
    async handleSetupSure() {
      const data = {
        payType: 1,
      };

      const res = await postTraceSetup(data);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "设置成功！",
        });
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 获取字符数量
    async getFontNumber() {
      const data = {
        deductNum: this.sum,
        type: 2,
      };
      const res = await checkInterestsNumber(data);
      if (res.code === 200 || res.code === 7002) {
        this.curCharacter = res.data;
        if (res.data / 10000 >= 1) {
          this.tabList[3].name =
            "字符余额 " + (res.data / 10000).toFixed(2) + "w";
        }
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 获取是否快捷支付状态
    async getTraceSourceSetUp() {
      const res = await getTraceSetup();
      if (res.code === 200) {
        this.payType = res.data.payType;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 顶部tab事件
    handleTab(index) {
      if (index === 0) {
        if (!getToken("auth_token")) {
          this.$message({
            message: "请先登录！",
            duration: 1500,
          });

          return window.loginFun();
        }
        this.sourceTimespan = true;
      } else if (index == 1) {
        if (!getToken("auth_token")) {
          this.$message({
            message: "请先登录！",
            duration: 1500,
          });

          return window.loginFun();
        }
        this.uploadFile();
      } else if (index == 2) {
        this.handleSave();
      }
    },
    // 富文本内容Change事件
    handleHtml(html) {
      this.html = html;
    },

    // 触发上传事件
    uploadFile() {
      this.$refs.backFile.value = "";
      this.$refs.backFile.dispatchEvent(new MouseEvent("click"));
    },
    // 上传word文件
    async realUpload(e) {
      let file = e.target.files[0];
      let form = new FormData();
      form.append("file", file);
      this.$store.commit("playLifeLoading", true);
      const res = await wrodFileToHtml(form);
      this.$store.commit("playLifeLoading", false);

      if (res.code == 200) {
        this.html = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 时间过滤
    format(time) {
      const d = new Date(time);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let date = d.getDate();
      month = month < 10 ? "0" + month : month;
      date = date < 10 ? "0" + date : date;
      return year + "-" + month + "-" + date;
    },

    // 开始溯源
    startTraceSource() {
      // 文件溯源
      if (this.upListDialog) {
        if (this.payType == 1) {
          if (this.curCharacter < this.sum) {
            this.dialogTips = true;
          } else {
            this.upFileTrace();
          }
        } else {
          this.dialogTips = true;
        }

        return;
      }

      if (this.sum == 0) {
        this.$message.error("溯源内容不能为空");
        return;
      }
      this.sourceTimespan = false;

      if (this.payType == 1) {
        if (this.curCharacter < this.sum) {
          this.dialogTips = true;
        } else {
          this.sureTraceSource();
        }
      } else {
        this.dialogTips = true;
      }
    },

    // 发送溯源内容
    async sureTraceSource() {
      this.progress = 0;
      this.$message({
        type: "success",
        message: "溯源中",
      });
      const data = {
        platform: 1,
        wordContent: this.html,
        userId: this.userId,
        traceModel: 1,
        fileName: "未命名",
        endDate: this.sourceEndTime,
      };

      const res = await traceSource(data);
      if (res.code == 200) {
        let resultId = res.data;

        this.Timer = setInterval(
          () => {
            this.getTraceSourceResultClick(resultId);
          },

          2000
        );

        this.handlerRefTraceList();
      }
    },

    async getTraceSourceResultClick(id) {
      //获取溯源结果
      // this.source_timer = setInterval(() => {}, 2000);
      if (this.progress < 80) {
        this.progress = this.progress + 10;
      }
      ++this.Count;
      if (this.Count == 20) {
        this.Count = 0;
        clearInterval(this.Timer);
        this.$alert("溯源时间较长,请稍后自行刷新溯源报告列表", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            // this.$message({
            //   type: "info",
            //   message: `action: ${action}`,
            // });
          },
        });
        return;
      }
      const params = { id };
      const res = await getTraceSourceResult(params);
      if (res.code == 200) {
        this.progress = 100;

        this.html = res.data.reportHtml;

        clearInterval(this.Timer);
        this.handlerRefTraceList();

        this.$message.success("溯源成功");
      }
    },
  },
  mounted() {
    this.getFontNumber();
    this.getTraceSourceSetUp();
  },
  created() {},
  watch: {
    html: {
      handler: function (cur) {
        if (cur && cur != "") {
          this.sum = cur.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, " ").length;
        } else {
          this.sum = "";
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.TraceSourceRouterView {
  width: 100%;

  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;
  .box {
    display: flex;
    width: 1338px;
    box-sizing: border-box;
    margin: 0 auto;
    .left {
      position: relative;
      width: 50%;
      margin-right: 10px;
      background-color: #fff;

      .Trace_box {
        .tab_box {
          position: relative;
          font-size: 13px;
          padding: 10px 20px;
          background-color: #ff690020;
          margin-bottom: 10px;
          @include flex-start;

          .sum_box {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
          }

          .item {
            margin-right: 20px;
          }
          .start {
            display: flex;
            align-items: center;
          }

          > div {
            @include flex-start;

            cursor: pointer;

            > span {
              font-size: 13px;
              font-weight: 500;
            }
            img {
              width: 16px;
              height: 16px;
              margin-right: 6px;
            }
          }
        }
      }
    }
    .right {
      background-color: #fff;
      width: 50%;
    }
  }

  .source-timespan {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      margin-top: 25vh !important;
      width: 423px;
      height: 258px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      border-radius: 6px;
      .el-dialog__header {
        padding: 10px 10px;
        background-color: #f4f6f7;
        line-height: 1;
        border-radius: 6px;
        width: 100%;
        box-sizing: border-box;
        .el-dialog__title {
          font-size: 14px;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 15px;
          right: 12px;
        }
      }
      .el-dialog__body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 20px 20px;
        .content {
          flex: 1;
          @include flex-center;
          justify-content: stretch;
          .time-wrap {
            flex: 1;
            // @include flex-between;
            display: flex;
            justify-content: center;
            align-items: center;
            .date-wrap {
              @include flex-center(column);
              justify-content: stretch;
              align-items: stretch;
              .title {
                text-align: center;
              }
              .el-date-editor.el-input {
                width: 180px;
                margin-top: 10px;
                .el-input__inner {
                  padding-left: 60px;
                }
              }
            }
            .bar {
              margin-top: 20px;
              vertical-align: middle;
              color: #999;
            }
          }
        }
        .footer {
          // @include flex-between(space-around);
          // display: flex;
          align-items: center;
          margin-top: 20px;
          text-align: center;
          > span {
            @include noselect;
            display: inline-block;
            width: 110px;
            height: 30px;
            border-radius: 5px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            background-color: #4587ff;
            cursor: pointer;
            &.cancel {
              background-color: #fff;
              border: 1px solid #4587ff;
              color: #4587ff;
            }
          }
          .cancel {
            margin-left: 20px;
          }
        }
      }
    }
  }

  .tips {
    ::v-deep .el-dialog {
      display: flex;
      flex-direction: column;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 340px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 12px 12px 0px;
        .el-dialog__title {
          font-size: $font-size-small;
          font-family: PingFang SC;
          font-weight: 400;
          color: $color-text-gray;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        padding: 15px 39px;
        flex: 1;
        .content {
          font-size: $font-size-medium-x;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
          line-height: 26px;
          display: flex;
          align-items: normal;
          span {
            color: #eb441e;
          }
          .content-icon {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            @include backgroundGroup("~assets/images/del-error.png");
            transform: translateY(4px);
            margin-right: 9px;
          }
        }
        .active {
          margin-top: 20px;
        }
        .footer {
          display: flex;
          justify-content: space-between;
          margin-top: 33px;
          .footer-btn {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
          }
        }
      }
    }
  }

  .commonFooter_box {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
